<template>
  <div style="margin-top: 10px;">
    <el-card class="box-card">
      <div class="text item">
        <div class="main">
          <div>
            <span class="autoheader">运单号</span>
            <input type="text" v-model="form.expressNo" />
          </div>
        </div>
        <div class="main">
          <div>
            <span class="autoheader">生成运单时间</span>
            <p>{{ detail.createTime }}</p>
          </div>
        </div>
        <div class="main">
          <div>
            <span class="autoheader">发货人</span>
            <p>{{ detail.shipper }}</p>
          </div>
        </div>
        <div class="main">
          <div>
            <span class="autoheader">手机号</span>
            <p>{{ detail.shipperPhone }}</p>
          </div>
        </div>
        <div class="main">
          <div>
            <span class="autoheader">发货地址</span>
            <p>{{ detail.shippingAddress }}</p>
          </div>
        </div>
        <div class="main">
          <div>
            <span class="autoheader">运费</span>
            <input type="text" v-model="form.freightAmountStr" />
          </div>
        </div>
        <div class="main">
          <div>
            <span class="autoheader">快递公司</span>
            <el-select
              v-model="form.expressCode"
              placeholder="请选择活动区域"
              class="select"
            >
              <el-option
                :label="item.expressName"
                :value="item.expressCode"
                v-for="item in expressArr"
                :key="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="main mainfooter">
          <div>
            <span class="autoheader">关联订单号</span>
            <p @click="Jump" class="order">{{ detail.orderCombineNo }}</p>
          </div>
        </div>
      </div>
      <el-row class="footer">
        <el-button  type="primary" class="footerbtn" @click="submit"
          >提交</el-button
        >
        <el-button  class="footerbtn" @click="returns"
          >返回</el-button
        >
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { logisticsEdit, logisticsDetail } from "@/api/logistics";
import { expressList } from "@/api/express";
import BigNumber from "bignumber.js";
export default {
  data() {
    return {
      form: {
        expressNo: "", //运单号
        freightAmountStr: "", //运费
        expressCode: "", //快递公司
        id: this.$route.query.id, //主键id
      },
      detail: {}, //运单详情
      expressArr: [], //快递公司列表
    };
  },
  async created() {
    //快递公司列表查询
    await expressList().then((res) => {
      this.expressArr = res.data.list;
    });
    // 根据id获取运单详情
    logisticsDetail({ id: this.$route.query.id }).then((res) => {
      this.form.expressNo = res.data.expressNo;
      this.form.freightAmountStr = res.data.freightAmountStr;
      this.form.expressCode = res.data.expressCode;
      this.detail = res.data;
    });
  },
  methods: {
    //提交
    submit() {
      this.obj = {
        ...this.form,
        freightAmount: BigNumber(this.form.freightAmountStr).times(100).toString(),
      };
      logisticsEdit(this.obj).then((res) => {
        this.$router.push("/logistics/list");
      });
    },
    // 返回
    returns() {
      this.$router.push("/logistics/list");
    },
    // 订单跳转
    Jump() {
      this.$router.push({
        path: "/order/detail",
        query: {
          id: this.detail.orderCombineId
        },
      });
    },
  },
};
</script>

<style lang='less' scoped>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 100%;
}
.mainfooter {
  border-bottom: 1px solid #efefef;
}
.main {
  .order {
    color: #279dfb;
  }
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-top: 1px solid #efefef;
  width: 100%;
  .autoheader {
    display: inline-block;
    height: 46px;
    width: 160px;
    border-right: 1px solid #efefef;
    background: #f9fafc;
    text-align: center;
    line-height: 46px;
    font-weight: 600;
    color: #333333;
    font-size: 14px;
  }
  p {
    display: inline-block;
    margin-left: 30px;
  }
  input {
    display: inline-block;
    margin-left: 15px;
    width: 360px;
    height: 24px;
    outline: none;
    border: 1px solid #efefef;
    padding: 5px;
    font-weight: normal;
  }
  /deep/ .select {
    margin-left: 15px;
  }
}
/deep/ .footer {
  text-align: center;
  .footerbtn {
    width: 120px;
  }
}
/deep/.el-card__body {
    padding: 20px;
    padding-top: 0px;
}
</style>
